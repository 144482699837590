













































































































































































































import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import { mapState } from 'vuex'
import { ApiResponse, BankDeposit, Structure } from '@/interfaces'
import { paymentTypes } from '@/formHelpers'
import { DatePicker } from 'element-ui'

interface lastNumberStructure {
  structureId: string
  lastNumber: string
}

interface Badge {
  type: string
  value: string
}

interface BankAccount {
  value: string
  label: string
  structureId: number
}

export default Vue.extend({
  components: { Nav },
  data() {
    return {
      bankDeposits: [] as BankDeposit[],
      period: [],
      periodOptions: {
        shortcuts: [
          {
            text: 'Mois dernier',
            onClick(picker: DatePicker) {
              const start = new Date()
              const startMonth = start.getMonth() - 1
              start.setDate(1)
              start.setMonth(startMonth)
              const end = new Date(start.getFullYear(), startMonth + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: 'Mois en cours',
            onClick(picker: DatePicker) {
              const start = new Date()
              start.setDate(1)
              const end = new Date(start.getFullYear(), start.getMonth() + 1, 0)
              picker.$emit('pick', [start, end])
            },
          },
        ],
      },
      structures: [] as Structure[],
      structureFilter: null,
      states: [
        { value: 'en_cours', label: 'En Cours', type: '' },
        { value: 'comptabilisee', label: 'Comptabilisée', type: 'success' },
      ],
      showDepositModal: false,
      allBankAccounts: [],
      bankAccounts: [],
      paymentTypes,
      bankDepositFormData: {} as BankDeposit,
      bankDepositFormIndex: null as number | null,
      lastNumberByStructure: [] as lastNumberStructure[],
      busy: false,
      numberFilter: null as string | null,
      amountFilter: null as string | null,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    user(newVal) {
      this.structures = newVal.client?.structures
    },
  },
  mounted() {
    this.fetchBankDeposits()
    this.fetchBankAccounts()

    if (this.user?.client?.structures) {
      this.structures = this.user?.client?.structures
    }
  },
  methods: {
    fetchBankDeposits() {
      this.bankDeposits = []
      if (!this.period) {
        this.period = []
      }
      if (!this.busy) {
        this.busy = true

        const loading = this.$loading({
          target: '#bankDepositsTable',
          text: 'Chargement des données...',
        })

        this.$api
          .get('/bank-deposits', {
            params: {
              structure: this.structureFilter,
              period: this.period,
              number: this.numberFilter,
              amount: this.amountFilter,
            },
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bankDeposits = apiResponse.data.bankDeposits
            this.lastNumberByStructure = apiResponse.data.lastNumberByStructure
          })
          .catch(() => {
            this.$notify({
              type: 'error',
              title: 'Erreur',
              message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          })
          .finally(() => {
            this.busy = false
            loading.close()
            this.resetBankDepositFormData()
            this.closeDepositModal()
          })
      }
    },
    fetchBankAccounts() {
      this.allBankAccounts = []

      this.$api
        .get('/bank-accounts')
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.allBankAccounts = apiResponse.data
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            title: 'Erreur',
            message: 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
          })
        })
    },
    submit() {
      const data = {
        ...this.bankDepositFormData,
      }
      delete data.structure
      const loading = this.$loading({
        target: '#bankDepositsTable',
        text: 'Chargement des données...',
      })

      if (!this.bankDepositFormData.id) {
        // Store
        this.$api
          .post('/bank-deposit', data)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bankDeposits.unshift(apiResponse.data.newBankDeposit)
            this.lastNumberByStructure = apiResponse.data.lastNumberByStructure
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Remise en banque créée avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            this.resetBankDepositFormData()
            this.closeDepositModal()
            loading.close()
          })
      } else {
        // Edit
        this.$api
          .put(`/bank-deposit/${this.bankDepositFormData.id}`, {
            data,
            period: this.period,
            number: this.numberFilter,
            amount: this.amountFilter,
            structure: this.structureFilter ?? '',
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bankDeposits = apiResponse.data
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Remise en banque modifiée avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
            this.resetBankDepositFormData()
            this.closeDepositModal()
            loading.close()
          })
      }
    },
    updateBankDepositForm(structureId: string) {
      let bankDepositChrono = this.lastNumberByStructure.find(
        (structure: lastNumberStructure) => structure.structureId === structureId
      )
      if (bankDepositChrono) {
        this.bankDepositFormData.depositNumber = parseInt(bankDepositChrono.lastNumber) + 1
      } else {
        this.bankDepositFormData.depositNumber = 1
      }
      delete this.bankDepositFormData.bankAccount
      this.getBankAccount(structureId)
    },
    getBankAccount(structureId: string | null) {
      this.bankAccounts = this.allBankAccounts.filter(
        (bankAccount: BankAccount) => bankAccount.structureId.toString() === structureId
      )
    },
    resetBankDepositFormData() {
      this.bankDepositFormData = {}
      this.bankDepositFormIndex = null
    },
    deleteBankDeposit(bankDeposit: BankDeposit, index: number) {
      this.$confirm('Êtes-vous sûr(e) de vouloir supprimer cette remise ?', 'Confirmation', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        type: 'warning',
      }).then(() => {
        this.$api
          .delete(`/bank-deposit/${bankDeposit.id}`)
          .then(() => {
            this.bankDeposits.splice(index, 1)
            this.$notify({
              type: 'success',
              title: 'Succès',
              message: 'Remise en banque supprimée avec succès !',
            })
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.resetBankDepositFormData()
          })
      })
    },
    generateDepositDocument(bankDeposit: BankDeposit) {
      if (!this.busy) {
        this.busy = true

        this.$api
          .post(`/bank-deposit/generate/receipt/${bankDeposit.id}`)
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bankDeposits = apiResponse.data.bankDeposits
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/bank-deposit-receipt/${apiResponse.data.filename}/${this.user.client.id}/download`
            window.open(documentUrl, '_blank')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    depositExport(bankDeposit: BankDeposit) {
      if (!this.busy) {

        this.$confirm(
          "Souhaitez-vous mettre à jour l'état de la remise à comptabilisée ?",
          'Confirmation',
          {
            distinguishCancelAndClose: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Non',
            type: 'warning',
          }
        )
          .then(() => {
            this.generateDepositExportDocument(bankDeposit, true)
          })
          .catch((action) => {
            if (action === 'cancel') {
              this.generateDepositExportDocument(bankDeposit, false)
            }
          })


      }
    },
    generateDepositExportDocument(bankDeposit: BankDeposit, updateState: boolean) {
      if (!this.busy) {
        this.busy = true

        this.$api
          .post(`/bank-deposit/generate/export/${bankDeposit.id}`, {
            updateState,
          })
          .then((response) => {
            const apiResponse = response.data as ApiResponse

            this.bankDeposits = apiResponse.data.bankDeposits
            const documentUrl = `${process.env.VUE_APP_API_URL}/document/bank-deposit-export/${apiResponse.data.filename}/${this.user.client.id}/download`
            window.open(documentUrl, '_blank')
          })
          .catch((error) => {
            if (error.response) {
              const apiResponse = error.response.data as ApiResponse

              this.$notify({
                type: 'error',
                title: 'Erreur',
                message:
                  apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
              })
            }
          })
          .finally(() => {
            this.busy = false
          })
      }
    },
    formatDate(str: string): string {
      const date = Date.parse(str)
      if (!isNaN(date)) {
        return new Intl.DateTimeFormat('fr-FR').format(date)
      } else {
        return '-'
      }
    },
    formatCurrency(amount: number) {
      return Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount).toString()
    },
    getStateBadge(bankDeposit: BankDeposit): Badge {
      const filteredState = this.states.find((state) => state.value === bankDeposit.state)

      return {
        type: filteredState?.type ?? '',
        value: filteredState?.label ?? '-',
      }
    },
    getPaymentType(bankDeposit: BankDeposit) {
      return this.paymentTypes.find((payment) => payment.value === bankDeposit.type)?.label ?? ''
    },
    getBankLabel(bankDeposit: BankDeposit) {
      const selectedBankAccount = this.allBankAccounts.find(
        (bankAccount: BankAccount) =>
          bankAccount.structureId === bankDeposit.structureId &&
          bankAccount.value === bankDeposit.bankAccount?.toString()
      ) as BankAccount | undefined
      return selectedBankAccount ? selectedBankAccount?.label : '-'
    },
    displayPaymentList(row: BankDeposit, column: Record<string, string>, cell: HTMLTableCellElement): void {
      if (!cell.classList.contains('actions')) {
        this.goToPaymentList(row)
      }
    },
    goToPaymentList(bankDeposit: BankDeposit) {
      if (bankDeposit.id) {
        this.$router.push({
          name: 'payments',
          query: {
            depositId: bankDeposit.id?.toString(),
          },
        })
      }
    },
    openDepositModal(bankDeposit: BankDeposit | null = null, index: number | null = null) {
      if (bankDeposit) {
        this.bankDepositFormData = {
          ...bankDeposit,
        }
        this.bankDepositFormIndex = index
        this.getBankAccount(bankDeposit.structureId ? bankDeposit.structureId.toString() : '')
      }
      this.showDepositModal = true
    },
    closeDepositModal() {
      this.showDepositModal = false
      this.resetBankDepositFormData()
      this.bankAccounts = []
    },
    handleClose(done: CallableFunction) {
      this.closeDepositModal()
      done()
    },
  },
})
